export enum PdfTypes {
    TICKET = "ticket",
    CONCLUSION = "conclusion",
    ANNOUNCEMENT = "announcement",
    NAZK = "nazk",
    PQ = "pq",
    COMPLAINT = "complaint",
    DETERMINING_WINNER_OF_PROCUREMENT = "DETERMINING_WINNER_OF_PROCUREMENT",
    TENDER_REJECTION_PROTOCOL = "TENDER_REJECTION_PROTOCOL",
    PURCHASE_CANCELLATION_PROTOCOL = "PURCHASE_CANCELLATION_PROTOCOL",
    ANNUAL_PROCUREMENT_PLAN = "ANNUAL_PROCUREMENT_PLAN",
    PROTOCOL_CONSIDERATION_TENDER_OFFERS = "protocol_consideration_tender_offers",
}
