import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {AbstractLoaderStrategy} from "~/services/PDF/P7SLoader/AbstractLoaderStrategy";
import {P7SLoadResultType} from "~/types/pdf/P7SLoadResultType";
import {messages} from "~/config/messages";
import {Assert} from "~/utils/Assert";
import {ENCODING} from "~/constants/encoding";
import {PdfDocumentConfigType} from "~/types/pdf/PdfDocumentConfigType";
import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";
import {ArrayHandler} from "~/utils/ArrayHandler";
import {AwardStatus, AwardType} from "~/types/Tender/AwardType";
import {PdfError} from "~/services/Error/PdfError";
import {DocumentType} from "~/types/Tender/DocumentType";

export class DeterminingWinnerOfProcurementLoader extends AbstractLoaderStrategy implements LoaderStrategyInterface {
    public async load(object: AwardType, config: PdfDocumentConfigType): Promise<P7SLoadResultType> {
        const {documents, status} = object;
        Assert.isDefined(status, messages.error.undefinedStatus);
        Assert.isDefined(documents, messages.error.documentListUndefined);
        if (status !== AwardStatus.ACTIVE) {
            throw new PdfError(messages.error.awardStatusNotFind);
        }
        const url = this.getDocumentUrl(documents, config);
        const file = await this.getData(url);
        return {
            file,
            type: PdfTemplateTypes.DETERMINING_WINNER_OF_PROCUREMENT_TEMPLATE,
            encoding: config.encoding || ENCODING.UTF_16,
            url,
        };
    }

    private getDocumentUrl(documentList: DocumentType[], {date}: PdfDocumentConfigType): string {
        const documents = documentList.filter((doc: Record<string, any>) => doc.title === "sign.p7s" && this.approximateCheckDateModified(doc.dateModified, date));
        const document = ArrayHandler.getLastElement(documents);
        Assert.isDefined(document, messages.error.undefinedDocumentTitle);

        return document.url;
    }
}
