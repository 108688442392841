export const STRING = {
    EMPTY: "",
    DOT: ".",
    MINUS: "-",
    DASH: "—",
    WHITESPACE: " ",
    DELIMITER: {
        DOT: ". ",
        COMMA: ", ",
    },
    DISC: "•",
} as const;

export const DEFAULT_TEXT_FIELDS: Record<string, string> = {
    SPACE: STRING.EMPTY,
    SPACE_LARGE: "   ",
};

export const SIGN_TO_DOC_FRAME_ID = "signToDocFrameID";
export const CLASSIFICATION_CONSTANTS: { readonly [index: string]: string } = {
    DK021: "ДК021",
    CPV: "CPV",
};
