import {pqSpecificationTexts} from "~/config/pdf/pq/pqTexts";
import {PQspecificationListItem} from "~/types/PQ/PQTypes";
import {STRING} from "~/constants/string";

export const fuelListConfig: PQspecificationListItem[][] = [
    [
        {text: pqSpecificationTexts.contractTotalPrice},
        {
            path: "value.amount",
            default: "",
            functionName: "",
        },
        {text: pqSpecificationTexts.includingTax},
    ],
    [
        {text: pqSpecificationTexts.talonDeliveryPeriod},
        {
            path: "items[0].deliveryDate.endDate",
            default: "",
            functionName: "formatDate",
        },
        {text: pqSpecificationTexts.taloncalendarDays},
    ],
    [
        {text: pqSpecificationTexts.fuelDeliveryPlace},
        {
            path: "items[0].deliveryAddress",
            default: "",
            functionName: "customerLocation",
        },
        {text: STRING.DOT},
    ],
    [{text: pqSpecificationTexts.talonLimitDueDate}],
    [
        {text: pqSpecificationTexts.expirationDateFuel},
        {
            path: "period.endDate",
            default: pqSpecificationTexts.endDate,
            functionName: "formatDate",
        },
        {text: STRING.DOT},
    ],
];
