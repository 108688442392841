export enum PdfTemplateTypes {
    XML = "XML",
    KVT = "KVT",
    MONITORING = "MONITORING",
    ANNOUNCEMENT = "announcement",
    NAZK = "NAZK",
    PQ = "PQ",
    COMPLAINT = "COMPLAINT",
    ANNUAL_PROCUREMENT_PLAN = "ANNUAL_PROCUREMENT_PLAN",
    DETERMINING_WINNER_OF_PROCUREMENT_TEMPLATE = "DETERMINING_WINNER_OF_PROCUREMENT_TEMPLATE",
    TENDER_REJECTION_PROTOCOL_TEMPLATE = "TENDER_REJECTION_PROTOCOL_TEMPLATE",
    PURCHASE_CANCELLATION_PROTOCOL_TEMPLATE = "PURCHASE_CANCELLATION_PROTOCOL_TEMPLATE",
    PROTOCOL_CONSIDERATION_TENDER_OFFERS_TEMPLATE = "PROTOCOL_CONSIDERATION_TENDER_OFFERS_TEMPLATE",
}
