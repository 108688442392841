import {pqSpecificationTexts} from "~/config/pdf/pq/pqTexts";
import {PDF_FILED_KEYS} from "~/constants/pdf/pdfFieldKeys";
import {PQ_SPECIFICATION_HEADING_MARGIN} from "~/config/pdf/pq/margins";

export const generalSpecificationHeader = [
    {
        text: pqSpecificationTexts.addition1,
        style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
        pageBreak: "before",
    },
    {
        text: pqSpecificationTexts.toContract,
        style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
    },
    {
        text: pqSpecificationTexts.from,
        style: PDF_FILED_KEYS.SPECIFICATION_HEADING,
    },
    {
        text: pqSpecificationTexts.specification,
        style: PDF_FILED_KEYS.HEADING,
        margin: PQ_SPECIFICATION_HEADING_MARGIN,
    },
];
