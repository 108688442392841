import {TemplateCodesEnum} from "~/types/PQ/TemplateCodes.enum";
import {generalSpecificationHeader} from "~/config/pdf/pq/specificationHeaderConfigs/general";
import {medicineSpecificationHeader} from "~/config/pdf/pq/specificationHeaderConfigs/medicine";

export const TemplateToSpecificationHeaderMap = new Map<string, Record<string, any>[]>()
    .set(TemplateCodesEnum.FRUIT, generalSpecificationHeader)
    .set(TemplateCodesEnum.COMPUTER, generalSpecificationHeader)
    .set(TemplateCodesEnum.OTHER, generalSpecificationHeader)
    .set(TemplateCodesEnum.MEDICINE, medicineSpecificationHeader)
    .set(TemplateCodesEnum.PHARM, medicineSpecificationHeader)
    .set(TemplateCodesEnum.GAS, generalSpecificationHeader);
