import {PdfTemplateTypes} from "~/services/PDF/PdfTemplateTypes";

export enum PDFTypeResolver {
    TENDER_REJECTION_PROTOCOL_TEMPLATE = PdfTemplateTypes.TENDER_REJECTION_PROTOCOL_TEMPLATE,
    DETERMINING_WINNER_OF_PROCUREMENT_TEMPLATE = PdfTemplateTypes.DETERMINING_WINNER_OF_PROCUREMENT_TEMPLATE,
    PURCHASE_CANCELLATION_PROTOCOL_TEMPLATE = PdfTemplateTypes.PURCHASE_CANCELLATION_PROTOCOL_TEMPLATE,
    PROTOCOL_CONSIDERATION_TENDER_OFFERS_TEMPLATE = PdfTemplateTypes.PROTOCOL_CONSIDERATION_TENDER_OFFERS_TEMPLATE,

}

export enum PDFTenderType {
    AWARDS = "awards",
    BIDS = "bids",
    CANCELLATION = "cancellation",
    DOCUMENTS = "documents",
}
