export const PURCHASE_CANCELLATION_PROTOCOL: Record<string, string> = {
    title: "ПРОТОКОЛ",
    subtitle: "відміни закупівлі/лоту або визнання таким, що не відбувся",
    customer_info: "Найменування організатора закупівлі:",
    customer_category: "Категорія організатора закупівлі:",
    customer_category_header_table: "Найменування замовника",
    customer_edrpou: "Ідентифікаційний код організатора закупівлі в ЄДР:",
    customer_edrpou_header_table: "Ідентифікаційний код замовника в ЄДР",
    customer_location: "Місцезнаходження організатора закупівлі:",
    customer_location_header_table: "Місцезнаходження замовника",
    type_of_purchase: "Вид закупівлі:",
    procuring_entity_title: "Назва предмета закупівлі:",
    participants_name: "Найменування учасника (для юридичної особи) або прізвище, ім’я, по батькові (для фізичної особи) щодо якого приймається рішення:",
    id_code: "Ідентифікаційний код учасника:",
    awards_value_amount: "Ціна тендерної пропозиції/пропозиції учасника:",
    awards_weighted_value: "Приведена ціна тендерної пропозиції/пропозиції учасника:",
    awards_amount_performance_value: "Показник ефективності енергосервісного договору:",
    with_tax: "з ПДВ",
    has_been_resolved: "Вирішено:",
    has_been_resolved_text: "Затвердити рішення про відміну закупівлі/лоту або визнання таким, що не відбувся, та опублікувати на веб-порталі Уповноваженого органу.",
    name_of_the_nomenclature_item_of_the_procurement: "Назва номенклатурної позиції предмета закупівлі",
    purchase_dictionary_code: "Код згідно з Єдиним закупівельним словником, що найбільше відповідає назві номенклатурної позиції предмета закупівлі",
    procurement_amount: "Кількість товарів або обсяг виконання робіт чи надання послуг",
    procurement_destination: "Місце поставки товарів або місце виконання робіт чи надання послуг",
    delivery_period: "Строк поставки товарів, виконання робіт чи надання послуг",
    dk_2015: "ДК 021:2015",
    grounds_for_rejecting_tender: "Підстава відміни закупівлі/лоту або визнання таким, що не відбувся:",
    description_grounds_for_rejecting_tender: "Обґрунтування відміни або визнання таким, що не відбувся:",
};
