import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {STRING} from "~/constants/string";
import {AnnouncementItem} from "~/types/Announcement/AnnouncementTypes";

export class UnitHelper {
    private strategy: AbstractDocumentStrategy;

    constructor(strategy: AbstractDocumentStrategy) {
        this.strategy = strategy;
    }

    public prepareUnitName(item: AnnouncementItem, recommendedDictionary: Record<string, any> | undefined): string {
        if (!this.strategy.emptyChecker.isEmptyString(this.strategy.getField(item, "unit.name"))) {
            return this.strategy.getField(item, "unit.name");
        }
        if (recommendedDictionary === undefined) {
            return STRING.DASH;
        }
        const unitDictionaryName = this.strategy.getField(recommendedDictionary, `${this.strategy.getField(item, "unit.code", "")}.name`, "");
        return this.strategy.emptyChecker.isEmptyString(unitDictionaryName) ? STRING.DASH : unitDictionaryName;
    }
}
