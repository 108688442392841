import {pqSpecificationTexts} from "~/config/pdf/pq/pqTexts";
import {PQspecificationListItem} from "~/types/PQ/PQTypes";
import {STRING} from "~/constants/string";

export const medicineListConfig: PQspecificationListItem[][] = [
    [
        {text: pqSpecificationTexts.refersTo},
        {
            path: "items[0].classification",
            default: pqSpecificationTexts.dkCode,
            functionName: "formatClassification",
        },
        {text: STRING.DOT},
    ],
    [
        {text: pqSpecificationTexts.contractTotalPrice},
        {
            path: "value.amount",
            default: "",
            functionName: "",
        },
        {text: pqSpecificationTexts.includingTax},
    ],
    [{text: pqSpecificationTexts.customerBudget}],
    [
        {text: pqSpecificationTexts.deliveryPlace},
        {
            path: "items[0].deliveryAddress",
            default: "",
            functionName: "customerLocation",
        },
        {text: STRING.DOT},
    ],
    [
        {text: pqSpecificationTexts.deliveryPeriod},
        {
            path: "period",
            default: "",
            functionName: "deliveryDateDiff",
        },
        {text: pqSpecificationTexts.calendarDays},
    ],
    [
        {text: pqSpecificationTexts.expirationDateMedicine},
        {
            path: "period.endDate",
            default: pqSpecificationTexts.endDate,
            functionName: "formatDate",
        },
        {text: pqSpecificationTexts.expirationDateMedicineFinish},
    ],
];
