const DATE_LENGTH_SHORT = 2;
const DATE_LENGTH_FULL = 4;
const DAY_START = 0;
const MONTH_START = 2;
const YEAR_START = 4;

export class StringHandler {
    /**
     * "03032021" => "03.03.2021"
     */
    public static formatToDate(str: string): string {
        const day: string = str.substr(DAY_START, DATE_LENGTH_SHORT);
        const month: string = str.substr(MONTH_START, DATE_LENGTH_SHORT);
        const year: string = str.substr(YEAR_START, DATE_LENGTH_FULL);
        return `${day}.${month}.${year}`;
    }

    public currencyFormatting(currency: string): string {
        // 483500 -> 483 500,00
        const [full, decimal] = currency.toString().split(".");
        let decimal_part;
        if (decimal) {
            switch (true) {
                case decimal.length > 1:
                    decimal_part = decimal;
                    break;
                case decimal.length === 1:
                    decimal_part = `${decimal}0`;
                    break;
                default:
                    decimal_part = "00";
            }
        } else {
            decimal_part = "00";
        }

        return [full.replace(/\B(?=(\d{3})+(?!\d))/g, " "), decimal_part].join(",");
    }
}
