import {AxiosStatic} from "axios";
import {PdfTypes} from "~/services/PDF/PdfTypes";
import {ConclusionLoader} from "~/services/PDF/P7SLoader/loaders/ConclusionLoader.ts";
import {TicketLoader} from "~/services/PDF/P7SLoader/loaders/TicketLoader.ts";
import {LoaderStrategyInterface} from "~/services/PDF/P7SLoader/LoaderStrategyInterface";
import {Base64Interface} from "~/utils/Base64Interface";
import {AnnouncementLoader} from "~/services/PDF/P7SLoader/loaders/AnouncementLoader";
import {NazkLoader} from "~/services/PDF/P7SLoader/loaders/NazkLoader";
import {PQLoader} from "~/services/PDF/P7SLoader/loaders/PQLoader";
import {ComplaintLoader} from "~/services/PDF/P7SLoader/loaders/ComplaintLoader";
import {TenderRejectionProtocolLoader} from "~/services/PDF/P7SLoader/loaders/TenderRejectionProtocolLoader";
import {DeterminingWinnerOfProcurementLoader} from "~/services/PDF/P7SLoader/loaders/DeterminingWinnerOfProcurementLoader";
import {PurchaseCancellationProtocolLoader} from "~/services/PDF/P7SLoader/loaders/PurchaseCancellationProtocolLoader";
import {AnnualProcurementPlan} from "~/services/PDF/P7SLoader/loaders/AnnualProcurementPlan";
import {ProtocolConsiderationTenderOffersLoader} from "~/services/PDF/P7SLoader/loaders/ProtocolConsiderationTenderOffersLoader";

export const loaderStrategyMap = new Map<string, new(base64: Base64Interface, axios: AxiosStatic) => LoaderStrategyInterface>()
    .set(PdfTypes.TICKET, TicketLoader)
    .set(PdfTypes.CONCLUSION, ConclusionLoader)
    .set(PdfTypes.ANNOUNCEMENT, AnnouncementLoader)
    .set(PdfTypes.NAZK, NazkLoader)
    .set(PdfTypes.PQ, PQLoader)
    .set(PdfTypes.ANNUAL_PROCUREMENT_PLAN, AnnualProcurementPlan)
    .set(PdfTypes.TENDER_REJECTION_PROTOCOL, TenderRejectionProtocolLoader)
    .set(PdfTypes.DETERMINING_WINNER_OF_PROCUREMENT, DeterminingWinnerOfProcurementLoader)
    .set(PdfTypes.PURCHASE_CANCELLATION_PROTOCOL, PurchaseCancellationProtocolLoader)
    .set(PdfTypes.PROTOCOL_CONSIDERATION_TENDER_OFFERS, ProtocolConsiderationTenderOffersLoader)
    .set(PdfTypes.COMPLAINT, ComplaintLoader);
