import {AbstractDocumentStrategy} from "~/services/PDF/document/AbstractDocumentStrategy";
import {MoneyType} from "~/types/Tender/MoneyType";
import {STRING} from "~/constants/string";

export class AwardHelper {
    private strategy: AbstractDocumentStrategy;

    constructor(strategy: AbstractDocumentStrategy) {
        this.strategy = strategy;
    }

    public showAwardPerformance(currencyBlock: MoneyType, mainText: string, withTaxText: string, showDefaultIfNotAvailable = true): Record<string, any> {
        if (!currencyBlock.hasOwnProperty("amountPerformance")) {
            return !showDefaultIfNotAvailable ? this.strategy.showIfAvailable("", "", false) : this.strategy.showWithDefault(STRING.DASH, mainText);
        }
        let text = `${this.strategy.getField(currencyBlock, "amountPerformance")} ${this.strategy.getField(currencyBlock, "currency")}`;
        const hasTax = this.strategy.getField(currencyBlock, "valueAddedTaxIncluded");
        if (text.length && true === hasTax) {
            text += ` ${withTaxText}`;
        }
        return !showDefaultIfNotAvailable ? this.strategy.showIfAvailable(text, mainText, text.length > 0) : this.strategy.showWithDefault(text, mainText);
    }
}
