export const messages = {
    error: {
        libraryInit: "Бібліотека не була ініціалізована",
        incorrectInputFormat: "Не вірний формат вхідних даних",
        undefinedUrl: "Відсутнє посилання на об'єкт ЦБД",
        undefinedObject: "Дані об'єкта ЦБД відсутні",
        undefinedAwards: "Відсутнє поле awards об'єкта з ЦБД",
        undefinedStatus: "Відсутнє поле status об'єкта award",
        undefinedConclusion: "Відсутнє поле conclusion об'єкта з ЦБД",
        undefinedConclusionOfDocs: "Відсутнє поле document в об'єкті conclusion об'єкта з ЦБД",
        undefinedDocumentTitle: "Не вдалося знайти ім'я документу в об'єкті",
        wrongDocumentType: "Не вдалося визначити тип документу",
        documentAccess: "Виникла помилка при отриманні документу із ЦБД",
        documentEncoding: "Виникла помилка при розшифруванні файлу підпису",
        signersObjectUnavailable: "Виникла помилка при формуванні колонтитулу з підписом для оголошення про проведення тендеру",
        documentListUndefined: "Список документів тендеру для вибору з них посилання підпису пустий",
        wrongDocumentTypeStatus: "Невірний тип документу",
        wrongDocumentTitle: "Невірний заголовок документу",
        wrongURL: "Невірне посилання на документ",
        tenderLoader: "Виникла помилка при завантаженні документу тендеру(Не передано config.tender)",
        undefinedCancellationStatus: "Статус відміни закупівлі відсутній",
        awardStatusNotFind: "Статус в award не відповідає заданим умовам",
    },
} as const;
